.item__container {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 5px;
    padding: 0.5%;
    margin: 5% 1% !important;
    background-color: var(--color-dominant);
    color: var(--color-complimant);
    max-width: 900px;
}

.item__container:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

.item__img {
    border-radius: 5px;
}

.item__text{
    padding: 2%;
}