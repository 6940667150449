.header__container {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  width: max-content;
  display: block;
  z-index: 2;

  background-color: var(--color-complimant);
  color: var(--color-dominant);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  gap: 0.8rem;
  /* padding: 1%; */
}

.header__title a {
  margin: 0.5rem;
  font-size: calc(1rem + 1.1vw);
  color: var(--color-compliment);
}

.header__nav ul {
  display: flex;
  flex: 1;
  flex-flow: row wrap;
  justify-content: right;
}

.header__nav li {
  list-style: none;
}

.header__nav li a {
  background: transparent;
  padding: 1rem;
  display: block;
  color: var(--color-compliment);
}

.header__nav li a:hover {
  background: rgba(0, 0, 0, 0.3);
  color: var(--color-accent);
}

.header__nav li .active {
  background-color: var(--color-compliment);
  background: rgba(0, 0, 0, 0.3);
  color: var(--color-accent-varient);
}

.header__toggleButton {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  background-color: var(--color-compliment);

  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 4px;
  background-color:  var(--color-dominant);

  transition: var(--transition);
}

/* .change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
} */

@media (max-width: 800px) {
  .header__toggleButton {
    display: flex;
  }

  .header__nav {
    display: none;
    width: 100%;
  }

  .active {
    display: flex;
  }

  .header__container {
    flex-direction: column;
    align-items: flex-start;
  }

  .header__nav ul {
    width: 100%;
    flex-direction: column;
  }

  .header__nav li {
    text-align: center;
  }

  .header__nav li a {
    padding: 0.5rem 1rem;
  }
}
