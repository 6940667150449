.home__trioImg {
  flex-flow: column;
  
}

.home__trioText{
  flex: 0 0 auto;
}

.home__trioImg {
  flex: 1;
}

.about__menu {
  line-height: 3rem;
  gap: 2rem;
}

.about__menuBtn {
  padding: 1%;
  background-color: var(--color-complimant);
  border-radius: 5%;
  color: var(--color-dominant);
}

.about__menuBtn:hover {
  background: rgba(0, 0, 0, 0.3);
}

.bg-contentBox {
  background-clip: content-box;
}