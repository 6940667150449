.footer__container, .mobileFooter__container{
    background-color: var(--color-complimant);
    color: var(--color-dominant);
}

.footer__container {
    display: flex;
    flex-flow: row wrap;
}

.mobileFooter__container {
    display: none;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
}

.footer__col {
    min-width: 100px;
    padding: 1%;
    text-align: center;
    display: flex;
    flex-flow: column;
}

.footer__contact {
    flex: 1 1 100px;
}

.footer__hours {
    flex: 1 1 150px;
}

.footer__pay {
    font-size: 2rem;
    gap: 5%;
    flex: 1 1 150px;
}

@media (max-width: 800px) {

    .footer__container {
        display: none;
    }

    .mobileFooter__container{
        display: flex;
        flex-flow: row wrap;
    }

}
