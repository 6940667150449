@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-dominant: #d4a370;
  --color-complimant: #633f10;
  --color-accent: #007561;
  --color-accent-varient: #00c9ae;

  --color-white: #fff;
  --color-light: rgba(12, 12, 12, 0.6);

  --transition: all 400ms ease;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Poppins", sans-serif; /*taken from google fonts*/
  background: var(--color-dominant);
  color: var(--color-complimant);
  line-height: 1.7;
  font-size: calc(1rem + 0.1vw);
  height: 100%;
  background-image: url(../src/assets/background.jpg);
}

/* --- General Styles --- */

.container {
  margin: 0 auto; /*Position in middle automatically*/
  width: 100%;
}

a {
  color: var(--color-accent-varient);
  transition: var(--transition);
}

a:hover {
  /*on hover*/
  color: var(--color-accent);
}

img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border: 4px solid var(--color-complimant);
}

li {
  text-align: left;
}

/* --- General Classes --- */

.bg-dom {
  background-color: var(--color-dominant);
  color: var(--color-complimant);
}

.top__imgContainer {
  width: 100%;
  height: 800px;
  line-height: 200px;
  text-align: center;
}

.top__imgContainer img {
  /* overflow: hidden;
    /* place-items: center; */
  /* background: linear-gradient(45deg, transparent, var(--color-accent), transparent); */
  /* width: 100%; */
  max-height: 800px;
}

.container--flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.container__col--flex {
  min-width: 300px;
  flex: 1;
}

.centered {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.container__divide {
  background-color: var(--color-complimant);
  color: var(--color-dominant);
  font-size: calc(1rem + 3.1vw);
}

.menu__divide {
  background-color: var(--color-complimant);
  color: var(--color-dominant);
  font-size: calc(1rem + 2.0vw);
  padding: 10%;
  margin: 20% 0% 0% 0%;
}

.padded {
  padding: 0% 1% 1% 1%;
}

.col__text {
  background-color: var(--color-dominant);
  gap: 1rem;
  padding: 2%;
  border-radius: 1%;
  margin: 1%;
  border: 5px solid var(--color-complimant);
}

.img--full {
  height: 100%;
  width: 100%;
}

.width-eighty {
  min-width: 80%;
}

.textColorBlue {
  color: blue;
}

.marginTopSm {
  margin-top: 10%;
}

.marginTopLg {
  margin-top: 10%;
}



@media (max-width: 800px) {

  body {
    margin-bottom: 15%;
  }

}

@media (max-width: 500px) {

  body {
    margin-bottom: 30%;
  }

}

/* @media (min-width: 992px) {
    .container {
        background-color: red;
        width: 90%;
    }
}
@media (max-width: 992px) {
    .container {
        background-color: green;
        width: 95%;
    }
}
@media (max-width: 768px) {
    .container {
        background-color: yellow;
        width: 97%;
    }
}
@media (max-width: 480px) {
    .container {
        background-color: blue;
        width: 98%;
    }*/